import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, shallowEqual as useAppShallowEqual } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/user';
import adminReducer from './slices/admin';
import companyReducer from './slices/company';
import storeReducer from './slices/store';
import product_tagReducer from './slices/product_tag';
import productReducer from './slices/product';
import product_categoryReducer from './slices/product_category';
import product_brandReducer from './slices/product_brand';
import product_category_typeReducer from './slices/product_category_type';
import employeeReducer from './slices/employee';
import employee_positionReducer from './slices/employee_position';
import employee_statusReducer from './slices/employee_status';
import bankReducer from './slices/bank';
import payment_methodReducer from './slices/payment_method';
import expenseReducer from './slices/expense';
import expense_categoryReducer from './slices/expense_category';
import expense_tagReducer from './slices/expense_tag';
import incomeReducer from './slices/income';
import income_categoryReducer from './slices/income_category';
import purchase_orderReducer from './slices/purchase_order';
import salesReducer from './slices/sales';
import sales_reportReducer from './slices/sales_report';
import inventory_reportReducer from './slices/inventory_report';
import worktimeReducer from './slices/worktime';
import incentiveReducer from './slices/incentive';
import import_logReducer from './slices/import_log';

// ----------------------------------------------------------------------
const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  // whitelist: ["user"],
  whitelist: [],
};

const rootReducer = combineReducers({
  user : userReducer,
  admin : adminReducer,
  company : companyReducer,
  store : storeReducer,
  product_tag : product_tagReducer,
  product : productReducer,
  product_category : product_categoryReducer,
  product_brand : product_brandReducer,
  product_category_type : product_category_typeReducer,
  employee : employeeReducer,
  employee_position : employee_positionReducer,
  employee_status : employee_statusReducer,
  bank : bankReducer,
  payment_method : payment_methodReducer,
  expense : expenseReducer,
  expense_category : expense_categoryReducer,
  expense_tag : expense_tagReducer,
  income : incomeReducer,
  income_category : income_categoryReducer,
  purchase_order : purchase_orderReducer,
  sales : salesReducer,
  sales_report : sales_reportReducer,
  inventory_report : inventory_reportReducer,
  worktime : worktimeReducer,
  incentive : incentiveReducer,
  import_log : import_logReducer,
});
const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

const { dispatch } = store;
const useSelector = useAppSelector;
const shallowEqual = useAppShallowEqual;
const useDispatch = () => useAppDispatch();

export { store, persistor, dispatch, useSelector, shallowEqual, useDispatch };
// export { store, persistor, dispatch , useDispatch  };
